export const environment = {
  production: true,
  external_wms: false,
  app_level: 36,
  app_version: '2.3.8',
  public_loclogshop: 'https://loclogshops.com',
  app_checkout: 'https://pago.loclog.mx',
  appKey: '67P0nVRQL3z81y0rPvqUdMYYXCOOBoBllTdwQd14lVTgi5igfYtnyGCIvp3VclxdneB61uysUnpDQT340Q37ZvCmv2Ae6amC1ub',
  checkoutKey: 'aCDkjdI6Vz7dRYomREtgP7tXeyVifcwwKSkXeNd5nWGxACP5PArOtXwujLQKmiY1Qv9f8hCRrw67tbd9ELVhtmFplm7KGm1p09KLitLRymjvd1N4wNMCmbUzwx02dAnp8GHoSprXIFRuhbyXTMI85QgNzHSitrGiVA2KEcfYbSp0O6IPdHKo4LqMRXDfWGvFSubqeK4l',
  shipmentKey: '52a8jFuKub6dzD2snAWDRgZ5g5WxG7Nqvs02SucFYSD7oxHrnGYPB4sbDalh0VTkpba9wNcSDESiAe3yFROmIX36Bw4yGBcXrrHLb9KVrzZZKn5r9GuKzmZPu3UQ2q6aL6QQN648XXTbwXRIu9i4w26Q1njD33u0WSdAQVHdHxfL91uQmNUJgdYqht2UYkVi2uGnzItq',
  countryCode: 'MX',
  currencyCode: 'MXN',
  /* Configuración ENTORNOS */
  session_manager_v1: 'https://sessions.pickerpro.services/v1.0.0',
  // session_manager_v1: 'https://pickerpro-sessions-ssesa.ondigitalocean.app/v1.0.0',
  session_manager_key: 'PqqcFMzCB7Gz65XxsaZNanKpHHg9TvQ4kmeN0Q77ONjKmPbyiJiWj4H1h9YzyeTnvOaYXXWlhIVNKbHo8QV9I0MS22aK209Nf5g',
  // session_manager_v1: 'http://localhost:8439/v1.0.0',
  // session_manager_key: 'AU8RPxbjqt03L5rJNKppRjX7uc1Q2fjxsSxBzsyDBRAc0bLvkH8wS9JURANqxWZOj4RWF9wWu3KJ8qT0PfYrYVTsVCfzlEb9NfdmG4LE1sgLPJthnk1QhgY3RvqGQpPH27TW2Tn86Sx0IclzZVOmo8ISa7ZEueQ0k7BKZ4MUOT5QPLKqbclDNRSK7JgNa2dOcYMBf3',
  wms_v1: 'https://api.pickerpro.services/v1.0.0/ecommerce/loclog/b2b',
  wms_key: 'ePIBSnC1JIMp2SWNUNPDy0kvmJBfjEhRByLsHpBFKek4cnqPWWPA26HIQEOGmTZRKBcDQYhnuYcCVHikGJY9AfuphH3McuZXsn3',
  wms_key2: 'zswnkNsGqWXTeGb1clWA6TPoq06xUm3Bs4LYy6goAz22Dq0AnWxrqYbicSigiimV7aTuD7eQUgp6Bp99iBleANUhTMIFzPCkEbG',
  // wms_v1: 'http://localhost:8437/v1.0.0/ecommerce/loclog/b2b',
  // wms_key: 'ionT3b4SnL1sPsovz9Hz7qbyO1o5oRAzi6lvfOpgOGpAx3kKJcjJo9aNzL8YPBpKRvQkmOoXSfRZqSUT9888PK5LX63v0bliLWo',
  // wms_key2: 'zkQgBN1WWFRyMarSn5BPgpNcm3zUtPYPHzMMymhZP9y5fFEHIitEdHlkKKjuk2sJkiufsYw6ymevzNqcTB76xuk82OhZfTgKL1s',
  checkout_v2: 'https://shopping.pickerpro.services/v1.0.0/stores/b2b',
  checkout_key: 'OfMo3V4ykE8eK4rBhep8Je1E9eFrTsvfL7TC8jBLcCRznKHUYPgPRQxH2KBotVcmIHewP1wdKUundHc0kF3rUwRklpY8aJ7kQaa',
  // checkout_v2: 'http://localhost:8436/v1.0.0/stores/b2b',
  // checkout_key: 'l1Ma9qbekRWPBFbTBB6cZSXwMlvjoBLpL9naaSdMlLWrnVGim03OQbHqiMnRFj5ZqAHe2MBLUVoQy6lM1BwOOnyKBaZVFWcrUpL',
  shops_v3: 'https://shops.loclogapis.com/shops/v3.0.0',
  // shops_v3: 'https://betashops.loclogapis.com/shops/v3.0.0',
  // shops_v3: 'https://78ae-2806-103e-1d-2898-814-70f0-fa76-9e1c.ngrok.io/shops/v3.0.0',
  // shops_v3: 'http://localhost:8443/shops/v3.0.0',
  shipments_v1: 'https://shipments.loclogapis.com/v1.0.0',
  // shipments_v1: 'http://localhost:8442/v1.0.0',
  payments_v2: 'https://checkout.loclogapis.com/v1.0.0',
  // payments_v2: 'http://localhost:8441/v1.0.0',
  /* Configuración Basic
  isBasic: true,
  isB2C: true,
  show_confirm_cart_modal: false,
  back_to_store_after_add_in_detail: false,
  show_icon_search_on_web: true,
  smartbCompatible: false,
  searchType: 'title_gtin',
  isWMS_Compatible: false,
  showStock: false,
  */
  /* Configuración B2C
  isBasic: false,
  isB2C: true,
  show_confirm_cart_modal: false,
  back_to_store_after_add_in_detail: false,
  show_icon_search_on_web: true,
  smartbCompatible: false,
  searchType: 'title_gtin',
  isWMS_Compatible: false,
  showStock: false,
  */
  /* Configuración B2B
  isBasic: false,
  isB2C: false,
  show_confirm_cart_modal: false,
  back_to_store_after_add_in_detail: true,
  show_icon_search_on_web: false,
  smartbCompatible: false,
  searchType: 'title_gtin_substance',
  isWMS_Compatible: true,
  showStock: false,
  */
  /* Configuración actual */
  isBasic: false,
  isB2C: false,
  show_confirm_cart_modal: false,
  back_to_store_after_add_in_detail: true,
  show_icon_search_on_web: false,
  smartbCompatible: false,
  searchType: 'title_gtin_substance',
  isWMS_Compatible: true,
  showStock: false,
  /* Otras configuraciones */
  // app_checkout: 'http://localhost:3000',
  // shops_v1: 'https://shops.loclogapis.com/shops/v1.0.0',
  // shops_v1: 'https://betashops.loclogapis.com/shops/v1.0.0',
  // shops_v1: 'http://localhost:8443/shops/v1.0.0',
  // shops_v2: 'https://shops.loclogapis.com/shops/v2.0.0',
  // shops_v2: 'https://betashops.loclogapis.com/shops/v2.0.0',
  // shops_v2: 'http://localhost:8443/shops/v2.0.0',
  smartb_v1: 'http://localhost:8440/v1.0.0',
  smartb_store_id: '2692fd39-1197-4f02-9174-0a7020ff8d0f',
  // smartb_v1: 'httpS://smartb.loclogapis.com/v1.0.0',
  // smartb_store_id: '3579d77a-57f3-4baf-82c8-8d4e54fa37b2',
  // SOCKET STORE
  socket_url: 'wss://wshops.loclogapis.com/shops',
  // socket_url: 'ws://localhost:8080/shops',
  // SOCKET NOTIFY
  socket_url2: 'ws://localhost:8443/shops',
  keyNotify: '80ofbdjxMEBaSfKFTyOrLg18apLogPQHoLIFxst6qRZ5UskmbV9bt3j8Pmv9ew6tRkqIJsjiYn7SNfKcvY5MdmvSk0Ikmdi6KgciggLmInhkfN4iIzBdVyTvjwSChhRZwcss72aOTU6GwgrpfL8aJdCKDyBwm8Thx9blAOOKJf1LVBvZIbWtT0gMmUc04e3c0rOyUObH',
  // TEST 1 PRUEBAS
  // public_key: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGF2ZV9wdWJsaWNhIjoieHpHWWlHZVZ4SjJTTjRXWiIsImlhdCI6MTYxNDU3MjE1NiwiYXVkIjoidGVzdDEubG9jbG9nLm14In0.CoaHuOpiLcshC37ngtJGg92_Z1ByOh0vxfNcT08CRYU',
  // private_key: 'idx6nC1w5oosFXXnQFYmNetBEl4SiU67B04r8N2orgS1TN4y3w1GPabMY8ZyAcDRNMJba9T7ieYuj2k4dXWMCn1YMXdeZjboBQwJARF2ntm1D7BMruizE4yzZN85S5UG',
  // publicKey: 'xzGYiGeVxJ2SN4WZ'
  // ADRIMEZA
  // public_key: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGF2ZV9wdWJsaWNhIjoibHd4TVdKVG9SRWdzeXZxRiIsImlhdCI6MTYwNzMwMDI0NSwiYXVkIjoiYWRyaW1lemEuY29tIn0.Xw4CkRiu-Zdk0VIbo3g4TJ9TiJJyD4SEIa3Xq7QPshU',
  // private_key: 'yvZYcUlOEjrsUfkljrM5yVTBYfLAMA41sWDiA8BK2F52Nif10WEoPjd6TPeQ097IuVXPUgtpPcOQEmKJq05FPqwhB2YrbcEI719mIm181DzHxt3YXV4Jjn9Yd7zdsSlo',
  // publicKey: 'lwxMWJToREgsyvqF'
  // ZEUCO
  // public_key: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGF2ZV9wdWJsaWNhIjoiU29VYVQxNnk5UEJPZ2djSyIsImlhdCI6MTYyNDk4MzY0MH0.PLeDS8jhb2Yb1XVoJJjOgojZxvPWKsduczRwhF2gRj4',
  // private_key: 'orsCim7Tt720aag6NM7iiy6s2ikbIYYYWxz8ZUv6PXAfzwcaZhSGVrETDMeY1vsppIAVSSMmqsJHR6dmHt1wPLWjYRqBVw762jxKAfwsFnYPANNslxWVtuFwQ7fnEEWq',
  // publicKey: 'SoUaT16y9PBOggcK'
  // DIOSA VERDE
  // public_key: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGF2ZV9wdWJsaWNhIjoiYU1GMUhUOEgyQjhrckRtdiIsImlhdCI6MTYyNDA1NjczMX0.U4lwnVdG4B1s59fVU2nOmfCtOfALWES1oid_3XYElRc',
  // private_key: 'hETOLq7SK3C0uHgsMiJMViR5fR6StK2dnQtELNH5nJ7CKH5iLTRGmSjK2Ul7pey2cmShn3HgEN4vqZ09a0FRlymX7AYFpOw559UVD33mrnLuWOZhNVGem3wGJe0XWmMc',
  // publicKey: 'aMF1HT8H2B8krDmv'
  // SALON DE BLLEZA ALPHA
  // public_key: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGF2ZV9wdWJsaWNhIjoiMTY4NllleDJzVTRHTHBUaCIsImlhdCI6MTYyMzg4MTk3Nn0.fY9SM_D47vrfbeMqf7lK2aAJfM-cZUObIJQZlI-UV2U',
  // private_key: 'HkD57Hvcut8Lzx1ZqNrWjWZG08YkIOa4rdQjBaSIiFYl4qHXomzxnIxHIynQLY3kFWtJCrekbS1fNmCZeTl9GlMb4UH1fAz6VEFc8HNHe0wkg6MKqlL09YC4fxvOFNV0',
  // publicKey: '1686Yex2sU4GLpTh'
  // WENDY ESTRADA
  // public_key: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGF2ZV9wdWJsaWNhIjoiRlJpMUR5YjA1Tjd0ejlONyIsImlhdCI6MTYwNjQzNzI2NSwiYXVkIjoid2VuZHllc3RyYWRhLmNvbSJ9.2DLjkyQjLsOSrWNxB8hm2HWaMnfAlGEdndiY9-L9VZo',
  // private_key: 'ghSpLvpbo2aWRZLjA0esCACjW42NGHmFbb7bIWsavl6HTEzpPJZt6xO2yFhPPlap',
  // publicKey: 'FRi1Dyb05N7tz9N7'
  // TRÁFICO VERDE
  // public_key: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGF2ZV9wdWJsaWNhIjoiaTBUMFdyRkJsQnNLbG9TNiIsImlhdCI6MTYzMDM0OTgzNX0.8eNxeq-SNPCwdKoUaQgsbpQhysxYhT1V0sCG-9ls1RY',
  // private_key: 'kJk5R92SGh6xTTKiHYY6GsLlcKiTb7uImFYcYAk5m4CeZjucITHmYDf6v91jpLZJySXELIOJ5OyUBDpUBdZ6r2KcYgm33Ndp6qB9FEBcdoysfzcTrQkNuJc5ZCPTjAlA',
  // publicKey: 'i0T0WrFBlBsKloS6'
  // MV BABY SHOP
  // public_key: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGF2ZV9wdWJsaWNhIjoiZnpYcWNIRHN3RWZIWUsxYSIsImlhdCI6MTYzMTQ3MDUyOX0.Er_EjEpl6jifht4PQHJyHmoBv9aK7sbStK3pMAGK9oQ',
  // private_key: 'sJri283aswvZXH8u0zXMS8TXkGTtztaZ2DVwkhwtBnj16R9jBILhvVBAdS0Uylu7CyT4WrcuOmjFIffO8iDDMkgTADDdmhpra7MXaqgrSUKZhWyTFLgpjbYdV5qJikFP',
  // publicKey: 'fzXqcHDswEfHYK1a'
  // HAPPY BUBBY
  // public_key: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGF2ZV9wdWJsaWNhIjoiYUI1Y1k1WnpxR2ZTSUk3YSIsImlhdCI6MTYzMTcyODA0N30.wqVMhjeRcm0-b0jhKGA34xSd4NYUC6O4xNEL54lfn70',
  // private_key: 'RtdLw1fJ5iltHmbLEsFFJY7eMrN5I1o5fMeJuLm4UMMKlnv837tI5Zo1PNGsedTc9WiiMuHsxkTvi4pVr0QvK6H1JH76X2oeD5F0XsQScyCxwWSqEY4gY6CQ2mWIcCHD',
  // publicKey: 'aB5cY5ZzqGfSII7a'
  // AMEXME
  // public_key: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGF2ZV9wdWJsaWNhIjoieGNmMTN1d3pxU3h2WHA0MyIsImlhdCI6MTYzMTQ3MDE0M30._zNLC4Zt4tconJ1nW4Z2JtSkj7v242X3eSislKCDmP4',
  // private_key: 'FOVXRhd4U69aF9b2h3GJPaCFtoz2BpLKM5kMSBTwN405pcPfQDpBpXgxoFcpX2rzX9Bu8TxfNN0eryRZMhJH8IKl8VCtQWDdyxyychWb9D59U8qJ8gl8h1Pyjba04P1b',
  // publicKey: 'xcf13uwzqSxvXp43'
  // TRAPITOS
  // public_key: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGF2ZV9wdWJsaWNhIjoiNmM0N2l4WmJnOHNWSmJhQiIsImlhdCI6MTY0MDIzNTY1N30.uNepCL0MEtB0n1G49lnt2WS3OeDsynYmwJz1blEmw5c',
  // private_key: 'GM6KplHogqxa9YluoQnvjhu3dcfKRLuA8u8e56jYE0yVC6lBgbDgOxUl5mDh2T1CAbhQCjoaymnYlr1jHG8DvrIXufoJHhLN3Y9k3XRayQUIsyzZ6zFP1GrPlKfSxG8T',
  // publicKey: '6c47ixZbg8sVJbaB'
  // KOLOG
  // public_key: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGF2ZV9wdWJsaWNhIjoiR2IyQmRwRDJ0OEs5cjRJViIsImlhdCI6MTY0MDIyMTM2OH0.i86X4o6Caa_F0UCEBlus2T13hQNgQQ1hcmUiL5fEuqM',
  // private_key: 'tDPfoSn6KnHmzLUVzVLZnSI9OH0SzAVC41rRTrqvXZR2SYoL8BWne6i1qKejZTYv4dXpQjw6JdhrH9oUwuhz6c0jMb7tR9QHgPLASBOPMs62gqh5MmqkybRvuOP7M83B',
  // publicKey: 'Gb2BdpD2t8K9r4IV'
  // FUNNY STORE BAZAR
  // public_key: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGF2ZV9wdWJsaWNhIjoiSDJHaUFPWEdtbDN2amR1dSIsImlhdCI6MTY0MDQ3NDU2MH0.tgLpEl92AlWOvlLZ0nQcjtL8gunQAXokgCIEbmtHGi4',
  // private_key: '8u3wLEkPXBKr2kqwej3d6MhvCoUKIk4l4i9aHo8qg99zhvO3w8RYjMBvChvzIPymiTSU7ER1CQQKD0FxMKXxVzo4ZX8hjtlKhMerpF3f9SbgR6MocUXIkpq5BXqDbg6U',
  // publicKey: 'H2GiAOXGml3vjduu'
  // DEMO 1
  // public_key: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGF2ZV9wdWJsaWNhIjoiNlJENVpBOTllR2h6RXpPbiIsImlhdCI6MTY1MDM5MjkxMH0.VSjQGc7k-1KS34VUFqOApIqNVNBfMW0-Hg-2jnzz6G8',
  // private_key: 'unvXEjlra17fSed6bKDaYmIWZjRIqW8rCJ947QcN7CZpfm30IRVg0N6OebCTD7FThhQ9xldB5GcZ4F5OSPQcPj1fWFIP8F7kDp2K6FCwcyGlWbnGC3OXfJRagiPcPIsz',
  // publicKey: '6RD5ZA99eGhzEzOn'
  // SOPORTE 1
  // public_key: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGF2ZV9wdWJsaWNhIjoiVEVpOU9KcW9VQVJ3a2tzdSIsImlhdCI6MTY0ODgyNzA2Mn0.RxrbxMo2pGHX6tFXF60YXixuhZk6t3uaLipG6lwWXJE',
  // private_key: 'BYr0YXqcLS35tmWCsM5RvyMOz1VLJLMGcoUB6cp7BRvLU8mPDtm5aSBiwBjzQHpJnM08FMkPgMuD7AUTXnkwrC4LbQ8BBBn2rdYF2L1jgkEvtMwrucWfsspGeYaKx6SD',
  // publicKey: 'TEi9OJqoUARwkksu',
  // EMFA
  // public_key: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGF2ZV9wdWJsaWNhIjoiUEt3WVlhaUNUcUZmSllseiIsImlhdCI6MTYzODk3ODMxNn0.K0UbLKICLPPZlG6VrE3PU0ZmdyTKaHy4YL6qhCDY9ys',
  // private_key: 'vEvM0kujVqTf5MCf4uDQ3EnXoATPzvMUZwbKWlIR92wK9TPADh5ACplU1sZwi31jp176aiLXQbQ9LU4EVUk5HZr8p0Na6JEBLiXeGwsrkr3rLus8PDjXHAylmr7wMi28',
  // publicKey: 'PKwYYaiCTqFfJYlz'
};
